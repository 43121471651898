import React from 'react';

import asurionLogo from '../assets/asurion-logo.png';
import attLogo from '../assets/att-asurion-logo.png';

import '../styles/Footer.css';

const aLinkStyle = {
  marginBottom: 0,
}

const Footer = ({
  clientName,
}) => (
    <div
      className="footer"
      
      // css vars integration w/ atomic ui
      style={({
        'borderTop': window.theme['--footer-container-border-top'], 
      })}
    >
      <div className="footer-inside">
        <a-footer
          links='[{
            "url": "https://www.asurion.com/terms-conditions/",
            "text": "Terms of Use"
          },
          {
            "url": "https://www.asurion.com/privacy-policy/",
            "text": "Privacy Policy"
          }]'
          cssVars={`{"--footer-background": "${window.theme['--footer-background']}"}`}
        >
          {
            clientName === 'att'
              ? <a-link href="https://www.asurion.com/"
                target="_blank"
                style={aLinkStyle}>
                <img
                  className="footer-logo"
                  src={attLogo}
                  alt="Asurion Logo"
                />
              </a-link>
              : <a-link href="https://www.asurion.com/"
                target="_blank"
                style={aLinkStyle}>
                <img
                  className="footer-logo"
                  src={asurionLogo}
                  alt="Asurion Logo"
                />
              </a-link>
          }
        </a-footer>
      </div>
    </div >
  );


export default Footer;