const valueToReturnIfValid = true;

const validatePhoneNumber = (phoneNumber) => {
  const errorMessage = 'Invalid phone number.';

  /*
    Matches 10 digit phone numbers with sevearl different seperators and also no formatting option.
    More on this regex can be found here: https://stackoverflow.com/questions/432493/how-do-you-access-the-matched-groups-in-a-javascript-regular-expression
  */
  const phoneNumberRegex = new RegExp('^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$');
  const result = phoneNumberRegex.exec(phoneNumber);
  if (result === null) {
    return errorMessage;
  }

  return valueToReturnIfValid;
};

export default validatePhoneNumber;