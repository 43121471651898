const valueToReturnIfValid = true;

const validateLocation = (location) => {
  const errorMessage = 'Location must be chosen.';

  if (typeof location !== 'string' || location.length === 0) {
    return errorMessage
  }

  return valueToReturnIfValid;
};

export default validateLocation;