
import axios from 'axios';
import standardizePhoneNumber from '../utils/standardizePhoneNumber';
import { default as config } from '../config/config.json';

const registerDevice = async ({
  location,
  phoneNumber,
  accessCode,
  emailAddress,
  clientName,
  captchaToken,
}) => {
  try {
    const backendUrl = config.whitelistApi.url;
    const request = {
      captchaToken,
      clientName,
      formInput: {
        location,
        accessCode,
        emailAddress,
        phoneNumber: standardizePhoneNumber(phoneNumber),
      },
      validateStatus: () => true,
    };

    const response = await axios.post(backendUrl, request);
    const successCode = 200;
    if (response.status !== successCode) {
      return {
        success: true,
        validationResponse: response.data.validationResponse,
      };
    }

    const { validationResponse, clientData, } = response.data;
    return {
      success: true,
      validationResponse,
      clientData,
    };
  } catch (err) {
    const defaultError = { error: 'Please try again later.', validationResponse: {} };
    const userFaultStatusCode = 403;
    const response = err.response.status === userFaultStatusCode
      ? err.response.data
      : defaultError;

    return {
      success: false,
      ...response,
    };
  }
};

export default registerDevice;