import React from 'react';
import PropTypes from 'prop-types';

import asurionLogo from '../assets/asurion-logo.png';
import attLogo from '../assets/att-asurion-logo.png';

import '../styles/Header.css';
import { switchToTMobileTheme } from '../styles/setCssVarsPolyfill';

const Header = ({
  clientName,
}) => {

  /*
    Probably a better way/place to programmatically set css variable
    but am rushed for time right now.
  */
  const shouldStyleHeaderForTMobile = clientName === 't-mobile';
  if (shouldStyleHeaderForTMobile) {
    switchToTMobileTheme();
  }

  return (
    <div className='header-container'
    style={({'borderTop': window.theme['--customer-header-border']})}
    >
      {
        clientName === 'att'
          ? <img
            className='att-logo'
            src={attLogo}
            href="https://www.asurion.com/"
            alt={`Asurion-AT&T Logo`}
          ></img>
          : <img
            className='asurion-logo'
            src={asurionLogo}
            href="https://www.asurion.com/"
            alt={`Asurion Logo`}
          ></img>
      }
    </div>
  );
};

Header.propTypes = {
  clientName: PropTypes.string.isRequired,
};

export default Header;