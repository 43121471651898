
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import '../styles/WhitelistDeviceForm.css';

import Captcha from './Captcha';
import Dropdown from './Dropdown';

import validateWhitelistDeviceForm from '../utils/validators/';
import setClientAppLinks from '../utils/storage/setClientAppLinks';
import standardizePhoneNumber from '../utils/standardizePhoneNumber';

const defaultValidationState = {
  phoneNumber: true,
  accessCode: true,
  location: true,
  emailAddress: true,
};

const noErrorValue = 'NO_ERR';
const getFieldError = (validationFieldResult) => {
  if (validationFieldResult !== true) {
    const errorMessage = validationFieldResult;
    return errorMessage;
  }

  return noErrorValue;
};

const WhitelistDeviceForm = ({
  possibleLocations,
  labels,
  registerDevice,
  clientName,

  captchaConfig,

  onSuccessfulSubmit,

  requireCaptcha = true,

  setLoadingOn,
  setLoadingOff,
}) => {
  const [location, setLocation] = useState(possibleLocations[0]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [accessCode, setAccessCode] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [errorMessage, setErrorMessage] = useState(undefined);

  const [validationFields, setValidationFields] = useState(defaultValidationState);

  const [shouldExcecuteCaptcha, setShouldExcecuteCaptcha] = useState(false);
  const [shouldResetCaptcha, setShouldResetCaptcha] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(undefined);

  const isCaptchaInvalid = requireCaptcha && !captchaToken;

  const onCaptchaTokenSubmitForm = [
    () => {
      (async () => {
        if (isCaptchaInvalid) {
          return;
        }

        await initSubmitForm();
      })();
    },
    [captchaToken],
  ];
  useEffect(...onCaptchaTokenSubmitForm)

  const resetCaptcha = () => {
    setShouldResetCaptcha(true);
    setShouldExcecuteCaptcha(false);
    setCaptchaToken(undefined);
  };

  const initSubmitForm = async () => {
    setLoadingOn();

    const canSubmit = await canFormBeSubmitted();
    if (!canSubmit) {
      setLoadingOff();
      return;
    }

    if (isCaptchaInvalid) {
      setShouldExcecuteCaptcha(true);
      return;
    }

    await attemptSubmitForm();
  };

  const attemptSubmitForm = async () => {
    if (isCaptchaInvalid) {
      return;
    }

    const wasFormSubmitSuccesful = await submitForm();
    if (!wasFormSubmitSuccesful) {
      setLoadingOff();
      return;
    }

    onSuccessfulSubmit();
  };

  const canFormBeSubmitted = async () => {
    setValidationFields(defaultValidationState);

    const {
      isFormValid,
      validationResults,
    } = validateWhitelistDeviceForm({
      phoneNumber: standardizePhoneNumber(phoneNumber),
      accessCode,
      location,
      emailAddress,
    });
    if (!isFormValid) {
      setValidationFields(validationResults);
      return false;
    }

    return true;
  };

  const submitForm = async () => {
    try {
      const response = await registerDevice({
        captchaToken,
        clientName,
        location,
        emailAddress,
        phoneNumber,
        accessCode,
      });

      const {
        success,
        validationResponse,
        clientData,
        error,
      } = response;

      setErrorMessage(error);
      if (error) {
        resetCaptcha();
        return false;
      }

      if (success !== true) {
        resetCaptcha();
        setValidationFields(validationResponse);
        return false;
      }


      setClientAppLinks(clientData.appLinks)

      return true;
    } catch (err) {
      resetCaptcha();
      return false;
    }
  };

  const onCaptchaSuccess = (token) => {
    setShouldExcecuteCaptcha(false);
    setCaptchaToken(token);
  };

  const onCaptchaError = () => {
    resetCaptcha();
  };

  const onCaptchaResetComplete = () => {
    setShouldResetCaptcha(false);
  }

  const phoneError = getFieldError(validationFields.phoneNumber);
  const acessCodeError = getFieldError(validationFields.accessCode);
  const emailError = getFieldError(validationFields.emailAddress);

  return (
    <div className="whitelist-device-form-container">
      {
        errorMessage
          ? <a-alert
            id="error"
            type="danger">
            {errorMessage}
          </a-alert>
          : null
      }
      <form>
        <div className="form-field location-field" id="location">
          <a-label>{labels.location}</a-label>
          <Dropdown
            possibleValues={possibleLocations}
            setValue={setLocation}
            selectedValue={location}
          />
        </div>
        <div className="form-field" id="phoneNumber">
          <a-label>{labels.phoneNumber}</a-label>
          {
            phoneError !== noErrorValue
              ? <span className="error-text">{phoneError}</span>
              : null
          }
          <a-form-input
            value={phoneNumber}
            onInput={(e) => setPhoneNumber(e.target.value)}
          ></a-form-input>
        </div>
        <div className="form-field" id="accessCode">
          <a-label>{labels.accessCode}</a-label>
          {
            acessCodeError !== noErrorValue
              ? <span className="error-text">{acessCodeError}</span>
              : null
          }
          <a-form-input
            value={accessCode}
            onInput={(e) => setAccessCode(e.target.value)}
          ></a-form-input>
        </div>
        <div className="form-field" id="emailAddress">
          <a-label>{labels.emailAddress}</a-label>
          {
            emailError !== noErrorValue
              ? <span className="error-text">{emailError}</span>
              : null
          }
          <a-form-input
            value={emailAddress}
            onInput={(e) => setEmailAddress(e.target.value)}
          ></a-form-input>
        </div>
        <div className="form-field captcha-box">
          <Captcha
            shouldExcecuteCaptcha={shouldExcecuteCaptcha}
            shouldResetCaptcha={shouldResetCaptcha}
            scriptLink={captchaConfig.scriptLink}
            apiKey={captchaConfig.apiKey}
            onSuccess={onCaptchaSuccess}
            onError={onCaptchaError}
            onCaptchaResetComplete={onCaptchaResetComplete}
          />
        </div>
        <div className="submit-button" id="submitButton">
          <a-button
            cssVars={`{
              "--button-background-color--primary": "${window.theme['--button-background-color--primary']}",
              "--button-background-color--primary--hover": "${window.theme['--button-background-color--primary--hover']}",
              "--button-border-color--primary": "${window.theme['--button-border-color--primary']}",
              "--button-border-color--primary--hover": "${window.theme['--button-border-color--primary--hover']}",
              "--button-color--primary": "${window.theme['--button-color--primary']}",
              "--button-color--primary--hover": "${window.theme['--button-color--primary--hover']}"
            }`}
            class="primary"
            onClick={initSubmitForm}
          >Register</a-button>
        </div>
      </form>
    </div>
  );
};

WhitelistDeviceForm.propTypes = {
  possibleLocations: PropTypes.arrayOf(PropTypes.string).isRequired,
  registerDevice: PropTypes.func.isRequired,
  onSuccessfulSubmit: PropTypes.func.isRequired,
  setLoadingOn: PropTypes.func.isRequired,
  setLoadingOff: PropTypes.func.isRequired,
  clientName: PropTypes.string.isRequired,
  captchaConfig: PropTypes.shape({
    apiKey: PropTypes.string.isRequired,
    scriptLink: PropTypes.string.isRequired,
  }).isRequired,
  labels: PropTypes.shape({
    location: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    accessCode: PropTypes.string.isRequired,
    emailAddress: PropTypes.string.isRequired,
  }).isRequired,
};

export default WhitelistDeviceForm;