const attTheme = {
    '--heading-nowrap': 'normal',
    '--select-font-weight': 'normal',
    '--select-background-color': '#f9f9f9',
    '--input-background': '#f9f9f9',
    '--footer-background': '#f9f9f9',
    '--link-padding': 'calc(.75em - 2px) 1.5em calc(.75em - 2px) 0em',
    '--customer-header-border': '6px solid #0a9eda',
    '--button-background-color--primary': '#0a9eda',
    '--button-background-color--primary--hover': '#0a9edaba',
    '--footer-container-border-top': '4px solid #0a9eda',
    '--button-border-color--primary': '#0a9eda',
    '--button-border-color--primary--hover': '#0a9edaba',
    '--button-color--primary': 'white',
    '--button-color--primary--hover': 'white',
    '--header-container-border-top': '4px solid #0a9eda'
};

const tmobileTheme = {
    '--heading-nowrap': 'normal',
    '--select-font-weight': 'normal',
    '--select-background-color': '#f9f9f9',
    '--input-background': '#f9f9f9',
    '--footer-background': '#f9f9f9',
    '--link-padding': 'calc(.75em - 2px) 1.5em calc(.75em - 2px) 0em',
    '--button-background-color--primary': '#e1e1e1',
    '--button-background-color--primary--hover': '#e1e1e1ba',
    '--customer-header-border': '6px solid #f9f9f9',
    '--footer-container-border-top': '4px solid #e1e1e1',
    '--button-border-color--primary': '#e1e1e1',
    '--button-border-color--primary--hover': '#e1e1e1ba',
    '--button-color--primary': 'black',
    '--button-color--primary--hover': 'black',
};

export const switchToAttTheme = () => {
    setTheme(attTheme);
};

export const switchToTMobileTheme = () => {
    setTheme(tmobileTheme);
};

const setTheme = (cssVars) => {
    window.theme = cssVars;
};

export default setTheme;