import { default as config } from '../config/config.json';

import ROUTE_PATHS from '../utils/routes';

const defaultLanguage = 'englishText';
const CONFIG_KEYS = {
  CLIENTS_KEY: 'clients',
  LANGUAGE_NAME_MAP: 'clientLanguageSelectionMap',
};

const redirectToDefaultPage = () => {
  window.location.href = ROUTE_PATHS.DEFAULT_PATH;
};

const getBrowserLanguage = () => {
  const browserLanguage = navigator.language;
  return browserLanguage;
};

const selectLocaleConfigForClient = (allConfig, clientConfig, browserLanguage) => {
  const languageNames = allConfig[CONFIG_KEYS.LANGUAGE_NAME_MAP];
  const clientLanguage = languageNames[browserLanguage];

  const localeClientConfig = clientConfig.text[clientLanguage];
  if (typeof localeClientConfig === 'undefined') {
    const defaultLanguageConfig = clientConfig.text[defaultLanguage];
    return defaultLanguageConfig;
  }

  return localeClientConfig;
};

/*
  Written with async to enable extending this function
  to pulling configuration from a database or other place.
*/
const getClientConfig = async (clientName) => {
  const clients = config[CONFIG_KEYS.CLIENTS_KEY];
  if (typeof clients === 'undefined') {
    redirectToDefaultPage();
  }

  const clientConfig = clients[clientName];
  if (typeof clientConfig === 'undefined') {
    redirectToDefaultPage();
  }

  const browserLanguage = getBrowserLanguage();
  const localeConfigText = selectLocaleConfigForClient(config, clientConfig, browserLanguage);
  const clientConfigWithLocaleText = {
    ...clientConfig,
    ...localeConfigText,
  };

  return clientConfigWithLocaleText;
};

export default getClientConfig;
