import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const loadCaptchaScript = (scriptLink, callbackName) => () => {
  const script = document.createElement('script');
  script.src = `${scriptLink}?onload=${callbackName}&render=explicit`;
  script.async = true;
  script.defer = true;

  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  }
};

const makeFunctionGloballyAccessible = (func, globalName) => {
  // The Captcha script looks for callback functions from the global window object.
  window[globalName] = func;
};

let captchaId;

const Captcha = ({
  scriptLink,
  apiKey,
  shouldExcecuteCaptcha,
  shouldResetCaptcha,
  onCaptchaResetComplete,
  onSuccess,
  onError,
}) => {
  const renderCaptcha = () => {
    const captchaOptions = {
      sitekey: apiKey,
      callback: onSuccess,
      size: 'invisible',
      'expired-callback': onError,
    };

    // eslint-disable-next-line no-undef
    captchaId = grecaptcha.render('recaptchaContainer', captchaOptions);
  };

  const renderCaptchaGlobalName = 'renderInvisibleCaptcha';
  makeFunctionGloballyAccessible(renderCaptcha, renderCaptchaGlobalName);
  useEffect(loadCaptchaScript(scriptLink, renderCaptchaGlobalName), []);

  useEffect(() => {
    if (shouldExcecuteCaptcha) {
      // eslint-disable-next-line no-undef
      grecaptcha.execute()
    }
  }, [shouldExcecuteCaptcha]);

  useEffect(() => {
    if (shouldResetCaptcha) {
      // eslint-disable-next-line no-undef
      grecaptcha.reset(captchaId);
      onCaptchaResetComplete();
    }
  }, [shouldResetCaptcha]);

  return (
    <>
      <div id="recaptchaContainer"> </div>
    </>
  );
};


Captcha.propTypes = {
  scriptLink: PropTypes.string.isRequired,
  apiKey: PropTypes.string.isRequired,
  shouldExcecuteCaptcha: PropTypes.bool.isRequired,
  shouldResetCaptcha: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onCaptchaResetComplete: PropTypes.func.isRequired,
};

export default Captcha;