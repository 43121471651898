import React from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
} from "react-router-dom";

import '@asurion/atomic-ui-library/es5'

import '@asurion/asurion-design-assets-asurion/asurion.css'
import './styles/App.css';

import RegisterPage from './pages/RegisterPage';
import SuccessPage from './pages/SuccessPage';
import LoadConfigWrapper from './components/LoadConfigWrapper'

import ROUTE_PATHS from './utils/routes';

const App = () => (
  <div  className="page-container">
    <BrowserRouter>
      <Switch>
        <Route
          path={ROUTE_PATHS.SUCCESS_PATH}
          component={LoadConfigWrapper(SuccessPage)}
        />
        <Route
          path={ROUTE_PATHS.REGISTER_PATH}
          component={LoadConfigWrapper(RegisterPage)}
        />
        <Route
          render={() => {
            window.location.href = ROUTE_PATHS.DEFAULT_PATH;
            return null;
          }}
        />
      </Switch>
    </BrowserRouter>
  </div>
);

export default App;
