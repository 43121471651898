import React from 'react';
import PropTypes from 'prop-types';

import '../styles/CarrierInfo.css';

const CarrierInfo = ({
  header,
  subHeader,
  details,
  footnote,
}) => {

  return (
    <div className='carrierInfo-container'>
      <a-heading
        cssVars='{ "--heading-nowrap": "normal" }'
        size="4"
      >
        {header}
      </a-heading>
      <a-heading
        cssVars='{ "--heading-nowrap": "normal" }'
        size="3"
      >
        {subHeader}
      </a-heading>
      <div dangerouslySetInnerHTML={{ __html: details }} />
      <div className='footnote'>
        <a-paragraph>{footnote}</a-paragraph>
      </div>
    </div>
  );
};

CarrierInfo.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  footnote: PropTypes.string,
};

export default CarrierInfo;