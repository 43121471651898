import { default as config } from '../config/config.json';
const captchaConfigKey = 'captcha';

/*
  Written with async to enable extending this function
  to pulling configuration from a database or other place.
*/
const getCaptchaConfig = async () => {
  const captchaConfig = config[captchaConfigKey];
  return captchaConfig;
};

export default getCaptchaConfig;
