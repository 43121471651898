const valueToReturnIfValid = true;

const validateEmailAddress = (emailAddress) => {
  const errorMessage = 'Must be a valid email.';

  /*
    Matches for a @ and a .
  */
  const emailAddressRegex = new RegExp('.+[@].+[.].+');
  const result = emailAddressRegex.exec(emailAddress);
  if (result === null) {
    return errorMessage;
  }

  return valueToReturnIfValid;
};

export default validateEmailAddress;