import validateAccessCode from './validateAccessCode';
import validateEmailAddress from './validateEmailAddress';
import validatePhoneNumber from './validatePhoneNumber';
import validateLocation from './validateLocation';

const validateForm = ({
  phoneNumber,
  accessCode,
  location,
  emailAddress,
}) => {
  const validationResults = {
    phoneNumber: validatePhoneNumber(phoneNumber),
    accessCode: validateAccessCode(accessCode),
    location: validateLocation(location),
    emailAddress: validateEmailAddress(emailAddress),
  };

  return {
    isFormValid: isFormResultValid(validationResults),
    validationResults,
  };
};

const isFormResultValid = (validationResult) => {
  const validationResultValues = Object.values(validationResult);
  const isValid = validationResultValues.every(result => result === true);

  return isValid;
};

export default validateForm;