import React from 'react';
import PropTypes from 'prop-types';

import '../styles/AppList.css';

import AppDetails from './AppDetails';

const AppList = ({
  apps
}) => (
    <div className="apps-container">
      <div className="apps-heading">
        <a-heading size="4">Apps</a-heading>
      </div>
      <div className='appList-container'>
        {
          apps.map((appDetails, index) => {
            return <AppDetails
              key={index}
              appDetails={appDetails}
            />
          })
        }
      </div>
    </div>
  );

AppList.propTypes = {
  apps: PropTypes.arrayOf(PropTypes.shape({
    appName: PropTypes.string.isRequired,
    iosUrl: PropTypes.string.isRequired,
    androidUrl: PropTypes.string.isRequired,
  }))
};


export default AppList;