import React from 'react';
import PropTypes from 'prop-types';

import '../styles/AppDetails.css';

import iosIcon from '../assets/apple-app-store.png';
import androidIcon from '../assets/google-play-store.png';

const AppDetails = ({
  appDetails: {
    appName,
    iosUrl,
    androidUrl,
  },
}) => (
    <div className="app-details">
      <div className="app-name" >
        <a-heading size="2">{appName}</a-heading>
      </div>
      <div className='app-link-container'>
        <a rel="nofollow" className="app-link" href={iosUrl}><img className="ios-icon" src={iosIcon} alt={`${appName} IOS App Url`}></img></a>
        <a rel="nofollow"  className="app-link" href={androidUrl}><img className="android-icon" src={androidIcon} alt={`${appName} Android App Url`}></img></a>
      </div>
    </div>
  );

AppDetails.propTypes = {
  appDetails: PropTypes.shape({
    appName: PropTypes.string.isRequired,
    iosUrl: PropTypes.string.isRequired,
    androidUrl: PropTypes.string.isRequired,
  }).isRequired,
};

export default AppDetails;