import React, { useEffect, useState } from 'react';

import LoadingSpinner from '../components/LoadingSpinner';

import getClientConfig from '../services/getClientConfig';
import getCaptchaConfig from '../services/getCaptchaConfig';

const LoadConfigWrapper = function wrappedComponent(WrappedComponent) {
  return function NewComponent(props) {
    {
      const { clientName } = props.match.params;

      const [config, setConfig] = useState({
        captchaConfig: undefined,
        clientConfig: undefined,
      });

      const fetchConfig = async () => {
        const [
          clientConfigResponse,
          captchaConfigResponse,
        ] = await Promise.all([
          getClientConfig(clientName),
          getCaptchaConfig(),
        ]);

        setConfig({
          captchaConfig: captchaConfigResponse,
          clientConfig: clientConfigResponse,
        });
      }

      useEffect(() => { fetchConfig(); }, []);

      const wrappedComponentProps = {
        ...props,
        config: {
          clientConfig: config.clientConfig,
          captchaConfig: config.captchaConfig,
        },
      };

      return (
        <>
          {
            config.captchaConfig && config.clientConfig
              ? <WrappedComponent {...wrappedComponentProps} />
              : <LoadingSpinner />
          }
        </>
      );

    }
  }
}

export default LoadConfigWrapper;
