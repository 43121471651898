const valueToReturnIfValid = true;

const validateAccessCode = (accessCode) => {
  const errorMessage = 'Invalid access code.';
  if (typeof accessCode !== 'string' || accessCode === '') {
    return errorMessage;
  }

  return valueToReturnIfValid;
};

export default validateAccessCode;