import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import '../styles/Dropdown.css';

const addChangeListenerToDropdownEffect = (dropdownId, callback) => () => {
  const selectElement = document.getElementById(dropdownId);

  selectElement.addEventListener('change', callback);

  return () => {
    selectElement.removeEventListener('change', callback);
  };
};

const Dropdown = ({
  selectedValue,
  possibleValues,
  setValue,
}) => {

  /*
    Solves known a-select issue where onChange cannot be set from jsx a-select element.
  */
  const dropdownId = `dropdown-${Math.random()}`;
  useEffect(addChangeListenerToDropdownEffect(dropdownId, (e) => setValue(e.target.value)));

  return (
    <select
      id={dropdownId}
      className="custom-dropdown"
    >
      {possibleValues.map((location) => {
        const locationName = location.toUpperCase();
        const locationValue = location;

        return (
          <option
            value={locationValue}
            key={locationValue}
          >{locationName}</option>
        );
      })}
    </select>
  );
};

Dropdown.propTypes = {
  possibleValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  setValue: PropTypes.func.isRequired,
  selectedValue: PropTypes.string,
};

export default Dropdown;