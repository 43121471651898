import React from 'react';

import Header from '../components/Header';
import Footer from '../components/Footer';
import CarrierInfo from '../components/CarrierInfo';
import AppList from '../components/AppList';

import getClientAppLinks from '../utils/storage/getClientAppLinks';

import '../styles/SuccessPage.css';

const SuccessPage = ({
  history,
  match,
  config: {
    clientConfig,
  },
}) => {
  const { clientName } = match.params;

  const clientAppLinks = getClientAppLinks();;
  const canAccessSuccessPage = clientAppLinks;
  if (!canAccessSuccessPage) {
    history.push(`/${clientName}/whitelist`);
  }

  return (
    canAccessSuccessPage
      ? <>
        <div className='footer-spacing-wrapper'>
          <div className='header-and-body'>
            <Header
              clientName={clientName}
            />
            <div className='success-page'>
              <CarrierInfo
                header={clientConfig.carrierInfo.successPage.header}
                subHeader={clientConfig.carrierInfo.successPage.subHeader}
                details={clientConfig.carrierInfo.successPage.details}
                footnote={clientConfig.carrierInfo.successPage.footnote}
              />
              <AppList
                apps={clientAppLinks}
              />
            </div>
          </div>
          <Footer
            clientName={clientName}
          />
        </div>
      </>
      : null
  )
};

export default SuccessPage;