import React from 'react';
import loadingSpinner from '../assets/loadingSpinner.svg'
import tMobileLoadingSpinner from '../assets/tMobileSpinner.svg'

import '../styles/LoadingSpinner.css';

const LoadingSpinner = ({ clientName }) => {
  const loadingImage = clientName === 't-mobile' ? tMobileLoadingSpinner : loadingSpinner;

  return (
    <>
      <div className="screen-dimmer"></div>
      <img
        src={loadingImage}
        className="loading-spinner"
        alt="Loading..."
      />
    </>
  );
};

export default LoadingSpinner;