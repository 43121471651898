import { sessionStorageKeys } from './sessionStorageKeys';

export default () => {
  const clientAppLinks = localStorage.getItem(sessionStorageKeys.whitelistClientData);
  if (!clientAppLinks) {
    return undefined;
  }

  const parsedAppLinks = JSON.parse(clientAppLinks);
  return parsedAppLinks;
}