import React, { useState } from 'react';

import '../styles/RegisterPage.css';

import WhitelistDeviceForm from '../components/WhitelistDeviceForm';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CarrierInfo from '../components/CarrierInfo';
import LoadingSpinner from '../components/LoadingSpinner';

import registerDevice from '../services/registerDevice';

const RegisterPage = ({
  match,
  history,
  config: {
    clientConfig,
    captchaConfig,
  },
}) => {
  const { clientName } = match.params;

  const [isLoading, setIsLoading] = useState(false);
  const setLoadingOn = () => setIsLoading(true);
  const setLoadingOff = () => setIsLoading(false);

  const onSuccessfulSubmit = () => {
    history.push(`/${clientName}/whitelist/success`);
  };

  return (
    <>
      <div className='footer-spacing-wrapper'>
        <div className='header-and-body'>
          <Header
            clientName={clientName}
          />
          <div className="register-page">
            {
              isLoading
                ? <LoadingSpinner 
                  clientName={clientName}
                />
                : null
            }
            <CarrierInfo
              header={clientConfig.carrierInfo.registerPage.header}
              subHeader={clientConfig.carrierInfo.registerPage.subHeader}
              details={clientConfig.carrierInfo.registerPage.details}
              footnote={clientConfig.carrierInfo.registerPage.footnote}
            />
            <WhitelistDeviceForm
              possibleLocations={clientConfig.possibleLocations}
              labels={clientConfig.formLabels}
              registerDevice={registerDevice}
              clientName={clientName}
              captchaConfig={captchaConfig}
              setLoadingOn={setLoadingOn}
              setLoadingOff={setLoadingOff}
              onSuccessfulSubmit={onSuccessfulSubmit}
            />
          </div>
        </div>
        <Footer
          clientName={clientName}
        />
      </div>
    </>
  );
};

export default RegisterPage;
